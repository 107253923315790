import LocalizedStrings from 'react-localization';

let localizations = new LocalizedStrings({
    uk: {
        title: 'Акредитований центр сертифікації ключів',
        headerTitle: 'АЦСК',
        headerMainPageLink: 'Головна',
        headerCertificatesPageLink: 'Сертифікати',
        headerNormativeBasePageLink: 'Нормативна база',
        headerDocumentsPageLink: 'Документи',
        headerSmartIDPageLink: 'SmartID',
        headerOnlineServicesPageLink: 'Online-сервіси',
        headerFrequentlyAskedQuestionsLink: 'Часті питання',
        headerContactsPageLink: 'Контакти',
        headerLanguageUk: 'UA',
        headerLanguageEn: 'EN',
        techWorks1: 'В звязку з регламентними роботами АЦСК «PRIVATBANK» з',
        techWorks2: 'до',
        techWorks3: 'можлива тимчасова недоступність сервісів АЦСК',
        menuCertificatesPageLink: 'Сертифікати',
        menuNormativeBasePageLink: 'Нормативна база',
        menuDocumentsPageLink: 'Документи',
        menuSmartIDPageLink: 'SmartID',
        menuOnlineServicesPageLink: 'Online-сервіси',
        menuFrequentlyAskedQuestionsLink: 'Часті питання',
        menuContactsPageLink: 'Контакти',
        menuLogin: 'Увійти',
        menuMyCertificatesPageLink: 'Мої сертифікати',
        menuLogout: 'Вийти',
        mainIntroTitle: 'Акредитований центр сертифікації ключів',
        mainIntroTarget: 'Призначений для систем електронного документообігу',
        mainDescription: 'Заощаджуйте свій час та зменшуйте витрати на оформлення звітності, проведення комерційних операцій за допомогою КЕП, який є аналогом і має рівну юридичну силу власноручного підпису.',
        mainApplications: 'Застосування і можливості',
        mainApplication1: 'Заміна внутрішнього і зовнішнього паперового документообігу на електронний, що має аналогічну юридичну силу.',
        mainApplication2: 'Захист інформації надійними засобами криптографії, що відповідають державним стандартам.',
        mainApplication3: 'Фіксування часу підписання документа КЕП.',
        mainApplication4: 'Надання можливості перегляду статусу сертифіката шляхом перегляду статусу сертифіката на сторінці <a href="https://acsk.privatbank.ua/certificates/clients" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">Сертифікати</a> та шляхом формування списку відкликаних сертифікатів.',
        mainApplication5: 'Обмеження щодо використання кваліфікованих сертифікатів відкритих ключів, сформованих Надавачем зазначені у <a href="https://acsk.privatbank.ua/arch/docs/Reglament_ACSK_PB.pdf" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">Регламенті</a>.',
        mainGeneralInformation: 'Загальна інформація',
        mainGeneralInformation1: 'Засоби кваліфікованого електронного підпису чи печатки надаються у вигляді окремих апаратних чи апаратно-програмних засобів кваліфікованого електронного підпису, які є частиною програмно технічного комплексу, а також окремих програмних додатків або програмних модулів (криптобібліотек), що функціонують у складі інших програмних додатків.',
        mainRates: 'Тарифи',
        mainRatesColumn1Name: 'Тарифний план',
        mainRatesColumn2Name: 'Вартість з ПДВ',
        mainRatesColumn1Content1: 'Фізична особа',
        mainRatesColumn1Content2: '0.00 грн',
        mainRatesColumn2Content1: 'Фізична особа - підприємець',
        mainRatesColumn2Content2: '0.00 грн',
        mainRatesColumn3Content1: 'Юридична особа',
        mainRatesColumn3Content2: '0.00 грн',
        mainOnlineServices: 'Online-сервіси',
        servicesGeneration: 'Отримати сертифікат',
        servicesSign: 'Підписати документ',
        servicesUnpublication: 'Відмовитись від публікації сертифіката',
        servicesRevocation: 'Відкликати сертифікат',
        servicesVerificationSign: 'Перевірити підпис',
        servicesVerificationCertificate: 'Перевірити сертифікат',
        servicesInfoTooltip: 'Відео-інструкція',
        certificatesTitle: 'Сертифікати',
        certificatesClients: 'Клієнтам',
        certificatesAcsk: 'АЦСК',
        certificatesRevoked: 'Список відкликаних',
        certificatesPersonal: 'Особисті',
        clientsCertificatesInn: 'РНОКПП',
        clientsCertificatesInnTooltip1: 'Якщо у вас немає РНОКПП введіть серію та номер паспорта.',
        clientsCertificatesInnTooltip2: 'Приклад: АЕ121212',
        clientsCertificatesInnInputPlaceholder: '',
        clientsCertificatesEdrpou: 'ЄДРПОУ',
        clientsCertificatesEdrpouTooltip1: 'Якщо у вас немає ЄДРПОУ введіть серію та номер паспорта.',
        clientsCertificatesEdrpouTooltip2: 'Приклад: АЕ121212',
        clientsCertificatesEdrpouInputPlaceholder: '',
        clientsCertificatesSerialNumber: 'Серійний номер сертифікату',
        clientsCertificatesSerialNumberInputPlaceholder: '20-42 символів',
        clientsCertificatesWithRevoked: 'Враховуючи відкликані',
        clientsCertificatesSearch: 'Пошук',
        clientsCertificatesBack: 'Назад',
        clientsCertificatesFound: 'Знайдено: {size}',
        clientsCertificatesSearchInfoInn: 'РНОКПП: {inn}',
        clientsCertificatesSearchInfoEdrpou: 'ЄДРПОУ: {edrpou}',
        clientsCertificatesSearchInfoSerialNumber: 'Серійний номер: {serialNumber}',
        clientsCertificatesSearchInfoWithRevoked: 'Враховуючи відкликані',
        clientsCertificatesMultiCheckBox: 'Обрати всі',
        clientsCertificatesOwner: 'Власник',
        clientsCertificatesSerial: 'РН',
        clientsCertificatesStatus: 'Статус',
        clientsCertificatesStatusActive: 'Дійсний',
        clientsCertificatesStatusRevoked: 'Відкликаний',
        clientsCertificatesStatusBlocked: 'Заблокований',
        clientsCertificatesValidity: 'Термін дії',
        clientsCertificatesType: 'Тип',
        clientsCertificatesTypeSign: 'Підпис',
        clientsCertificatesTypeStamp: 'Печатка',
        clientsCertificatesKey: 'Ключ',
        clientsCertificatesSignJKS: 'Файловий підпис',
        clientsCertificatesStampJKS: 'Файлова печатка',
        clientsCertificatesSignSmartID: 'Хмарний підпис',
        clientsCertificatesStampSmartID: 'Хмарна печатка',
        clientsCertificatesChecked: 'Обрано:',
        clientsCertificatesCheckedMax: '(макс. {max})',
        clientsCertificatesDownloadCertificatesAction: 'Завантажити сертифікати',
        clientsCertificatesDownloadPdfsAction: 'Завантажити інфо',
        acskCertificatesLoading: 'Завантаження...',
        acskCertificatesDownloadAll: 'Завантажити всі сертифікати',
        acskCertificateValidity: 'Термін дії',
        acskCertificateSerial: 'РН',
        revokedCertificatesLoading: 'Завантаження...',
        revokedCertificateFullName: 'Повний ({name})',
        revokedCertificatePartName: 'Частковий ({name})',
        revokedCertificateStartDate: 'Дата оновлення',
        revokedCertificateNextDate: 'Наступнe оновлення',
        revokedCertificateSerial: 'РН',
        personalCertificatesLoading: 'Завантаження...',
        personalCertificatesEmpty: 'Нічого не знайдено',
        personalCertificatesSearch: 'Пошук',
        personalCertificatesAllStatusFilter: 'Всі статуси',
        personalCertificatesActiveStatusFilter: 'Активні',
        personalCertificatesRevokedStatusFilter: 'Відкликані',
        personalCertificatesBlockedStatusFilter: 'Заблоковані',
        personalCertificatesAllTypeFilter: 'Всі типи підпису',
        personalCertificatesSignTypeFilter: 'Підписи',
        personalCertificatesStampTypeFilter: 'Печатки',
        personalCertificatesAllKeyFilter: 'Всі типи сховищ ключів',
        personalCertificatesJksKeyFilter: 'Файлові сховища ключів',
        personalCertificatesSmartIdKeyFilter: 'SmartID',
        personalCertificatesAllUserTypeFilter: 'Всі типи користувачів',
        personalCertificatesIndividualUserTypeFilter: 'Фізичні особи',
        personalCertificatesLegalUserTypeFilter: 'Юридичні особи',
        personalCertificatesEmployeeUserTypeFilter: 'Співробітники банку',
        personalCertificatesAllOrgNameFilter: 'Всі організації',
        personalCertificatesDefaultSort: 'За замовчуванням',
        personalCertificatesStartDateSort: 'За датою початку',
        personalCertificatesRevokedDateSort: 'За датою відкликання',
        personalCertificatesStatusSort: 'За статусом',
        personalCertificatesTypeSort: 'За типом підпису',
        personalCertificatesKeySort: 'За типом сховища ключів',
        personalCertificatesOwnerSort: 'За власником',
        personalCertificatesOrgNameSort: 'За організацією',
        personalCertificatesUserTypeSort: 'За типом користувача',
        personalCertificatesMultiCheckBox: 'Обрати всі',
        personalCertificatesNotFound: 'Нічого не знайдено',
        personalCertificatesOwner: 'Власник',
        personalCertificatesSerial: 'РН',
        personalCertificatesStatus: 'Статус',
        personalCertificatesStatusActive: 'Дійсний',
        personalCertificatesStatusRevoked: 'Відкликаний',
        personalCertificatesStatusBlocked: 'Заблокований',
        personalCertificatesValidity: 'Термін дії',
        personalCertificatesType: 'Тип',
        personalCertificatesTypeSign: 'Підпис',
        personalCertificatesTypeStamp: 'Печатка',
        personalCertificatesKey: 'Ключ',
        personalCertificatesSignJKS: 'Файловий підпис',
        personalCertificatesStampJKS: 'Файлова печатка',
        personalCertificatesSignSmartID: 'Хмарний підпис',
        personalCertificatesInactiveSignSmartID: 'Користуватись ключем неможливо. Отримано більш новий сертифікат',
        personalCertificatesStampSmartID: 'Хмарна печатка',
        personalCertificatesPublication: 'Публікація',
        personalCertificatesPublic: 'Публічний сертифікат',
        personalCertificatesUnpublic: 'Непублічний сертифікат',
        personalCertificatesChecked: 'Обрано:',
        personalCertificatesCheckedMax: '(макс. {max})',
        personalCertificatesDownloadCertificatesAction: 'Завантажити сертифікати',
        personalCertificatesDownloadPdfsAction: 'Завантажити інфо',
        personalCertificatesRevokeCertificatesAction: 'Відкликати',
        personalCertificatesUnpublicateCertificatesAction: 'Скасувати публікацію',
        personalCertificatesGenerateCertificatesAction: 'Отримати новий',
        personalCertificatesAuthorization: 'Для роботи з особистими сертифікатами необхідно пройти авторизацію',
        personalCertificatesAuthorizationButton: 'Авторизуватись',
        normativeBaseTitle: 'Нормативна база',
        normativeBaseSection1: 'Президент України',
        normativeBaseSection1Document1: 'Указ «Положення про порядок здійснення криптографічного захисту інформації в Україні»',
        normativeBaseSection2: 'Верховна Рада України',
        normativeBaseSection2Document1: 'Закон України «Про інформацію» N 2657-XII від 02.10.1992',
        normativeBaseSection2Document2: 'Закон України «Про захист інформації в інформаційно-комунікаційних системах» N 80/94-ВР від 05.07.1994',
        normativeBaseSection2Document3: 'Закон України «Про банки і банківську діяльність» № 2121-III від 07.12.2000',
        normativeBaseSection2Document4: 'Закон України «Про електронні документи та електронний документообіг» № 851-IV від 22.05.2003',
        normativeBaseSection2Document5: 'Закон України «Про захист персональних даних» № 2297-VI від 01.06.2010',
        normativeBaseSection2Document6: 'Закон України «Про доступ до публічної інформації» № 2939-VI від 13.01.2011',
        normativeBaseSection2Document7: 'Закон України «Про ліцензування видів господарської діяльності» № 222-VIII від 02.03.2015',
        normativeBaseSection2Document8: 'Закон України «Про електронну ідентифікацію та електронні довірчі послуги» № 2801-IX від 01.12.2022',
        normativeBaseSection3: 'Кабінет Міністрів України',
        normativeBaseSection3Document1: 'Постанова від 16.11.2016 № 821 «Деякі питання ліцензування господарської діяльності з надання послуг у галузі криптографічного захисту інформації (крім послуг електронного цифрового підпису) та технічного захисту інформації за переліком, що визначається Кабінетом Міністрів України»',
        normativeBaseSection3Document2: 'Постанова від 10.05.2018 № 356 «Про внесення змін та визнання такими, що втратили чинність, деяких актів Кабінету Міністрів України у зв’язку з прийняттям Закону України “Про електронні довірчі послуги»',
        normativeBaseSection3Document3: 'Постанова від 19.09.2018 № 749 «Про затвердження Порядку використання електронних довірчих послуг в органах державної влади, органах місцевого самоврядування, підприємствах, установах та організаціях державної форми власності»',
        normativeBaseSection3Document4: 'Постанова від 26.09.2018 № 775 «Про затвердження обов’язкових вимог до Довірчого списку»',
        normativeBaseSection3Document5: 'Постанова від 10.10.2018 № 821 «Про затвердження Порядку зберігання документованої інформації та її передавання центральному засвідчувальному органу в разі припинення діяльності кваліфікованого надавача електронних довірчих послуг»',
        normativeBaseSection3Document6: 'Постанова від 31.10.2018 № 914 «Про затвердження критеріїв, за якими оцінюється ступінь ризику від провадження господарської діяльності у сфері надання електронних довірчих послуг та визначається періодичність проведення планових заходів державного нагляду (контролю) Адміністрацією Державної служби спеціального зв’язку та захисту інформації»',
        normativeBaseSection3Document7: 'Постанова від 28 червня 2024 р. № 764 «Деякі питання електронної ідентифікації та електронних довірчих послуг»',
        normativeBaseSection3Document8: 'Постанова від 18.12.2018 № 1215 «Про затвердження Порядку проведення процедури оцінки відповідності у сфері електронних довірчих послуг»',
        normativeBaseSection3Document9: 'Постанова від 23.01.2019 № 60 «Про затвердження Порядку взаємного визнання українських та іноземних сертифікатів відкритих ключів, електронних підписів, а також використання інформаційно- телекомунікаційної системи центрального засвідчувального органу для забезпечення визнання в Україні електронних довірчих послуг, іноземних сертифікатів відкритих ключів, що використовуються під час надання юридично значущих електронних послуг у процесі взаємодії між суб’єктами різних держав»',
        normativeBaseSection3Document10: 'Постанова від 19.06.2019 № 546 «Про затвердження Положення про інтегровану систему електронної ідентифікації»',
        normativeBaseSection3Document11: 'Постанова від 18.09.2019 № 856 «Питання Міністерства цифрової трансформації»',
        normativeBaseSection3Document12: 'Постанова від 11.12.2019 № 1068 «Про внесення змін до деяких постанов Кабінету Міністрів України»',
        normativeBaseSection3Document13: 'Постанова від 03.03.2020 № 193 «Про реалізацію експериментального проекту щодо забезпечення можливості використання удосконалених електронних підписів і печаток, які базуються на кваліфікованих сертифікатах відкритих ключів»',
        normativeBaseSection3Document14: 'Постанова від 29.04.2020 № 345 «Про реалізацію експериментального проекту щодо забезпечення безперервного надання кваліфікованих електронних довірчих послуг у разі заміни надавача таких послуг»',
        normativeBaseSection3Document15: 'Постанова від 02.09.2020 № 785 «Про реалізацію експериментального проекту щодо використання віддаленого кваліфікованого електронного підпису Смарт-Дія»',
        normativeBaseSection3Document16: 'Постанова від 23.02.2022 № 229 «Про внесення змін до постанов Кабінету Міністрів України від 9 грудня 2020 р. № 1236 і від 29 червня 2021 р. № 677»',
        normativeBaseSection3Document17: 'Постанова від 17.03.2022 № 300 «Деякі питання забезпечення безперебійного функціонування системи надання електронних довірчих послуг»',
        normativeBaseSection3Document18: 'Постанова від 24.05.2022 № 617 «Про внесення змін до постанови Кабінету Міністрів України від 17 березня 2022 р. № 300»',
        normativeBaseSection4: 'Національний Банк України',
        normativeBaseSection4Document1: 'Постанова від 04.07.2007 № 243 «Про затвердження Правил з технічного захисту інформації для приміщень банків, у яких обробляються електронні банківські документи»',
        normativeBaseSection4Document2: 'Постанова від 26.11.2015 № 829 «Про затвердження нормативно-правових актів з питань інформаційної безпеки»',
        normativeBaseSection4Document3: 'Постанова від 14.08.2017 № 78 «Про затвердження Положення про застосування електронного підпису в банківській системі України»',
        normativeBaseSection4Document4: 'Постанова від 28.09.2017 № 95 «Про затвердження Положення про організацію заходів із забезпечення інформаційної безпеки в банківській системі України»',
        normativeBaseSection4Document5: 'Постанова від 19.09.2019 № 116 «Про затвердження Положення про кваліфікованих надавачів електронних довірчих послуг, внесених до Довірчого списку за поданням засвідчувального центру»',
        normativeBaseSection4Document6: 'Постанова від 13.12.2019 № 151 «Про затвердження Положення про застосування цифрового власноручного підпису в банківській системі України»',
        normativeBaseSection4Document7: 'Постанова від 24.12.2019 № 153 «Про затвердження Положення про синхронізацію часу у програмно-технічних комплексах кваліфікованих надавачів електронних довірчих послуг, внесених до Довірчого списку за поданням засвідчувального центру»',
        normativeBaseSection4Document8: 'Постанова від 17.02.2020 № 19 «Про затвердження Правил передавання документованої інформації до засвідчувального центру кваліфікованими надавачами електронних довірчих послуг, відомості про яких унесені до Довірчого списку за поданням засвідчувального центру»',
        normativeBaseSection4Document9: 'Постанова від 17.03.2020 № 32 «Про затвердження Положення про Систему BankID Національного банку України»',
        normativeBaseSection4Document10: 'Постанова від 24.03.2023 № 31 «Про внесення зміни до Положення про порядок ідентифікації та визнання банківських груп»',
        normativeBaseSection4Document11: 'Постанова від 27.03.2023 № 35 «Про затвердження Змін до Положення про кваліфікованих надавачів електронних довірчих послуг, внесених до Довірчого списку за поданням засвідчувального центру»',
        normativeBaseSection5: 'СБУ, ДССЗЗІ, МінЮст та МінЦифра',
        normativeBaseSection5Document1: 'Наказ від 16.05.2007 № 93 «Про затвердження Положення про державну експертизу в сфері технічного захисту інформації»',
        normativeBaseSection5Document2: 'Наказ від 12.06.2007 № 114 «Про затвердження Інструкції про порядок постачання і використання ключів до засобів криптографічного захисту інформації»',
        normativeBaseSection5Document3: 'Наказ від 20.07.2007 № 141 «Про затвердження Положення про порядок розроблення, виробництва та експлуатації засобів криптографічного захисту інформації»',
        normativeBaseSection5Document4: 'Наказ від 23.06.2008 № 100 «Про затвердження Положення про державну експертизу у сфері криптографічного захисту інформації»',
        normativeBaseSection5Document5: 'Наказ від 05.03.2012 № 368/5 «Про затвердження Вимог до написання найменування юридичної особи, її відокремленого підрозділу, громадського формування, що не має статусу юридичної особи, крім організації профспілки»',
        normativeBaseSection5Document6: 'Роз\'яснення Міністерства юстиції України щодо порядку обчислення геш-значення, викладені у Листі Міністерства юстиції України від 15.10.2012 № 12776-026-12/133',
        normativeBaseSection5Document7: 'Наказ від 15.04.2013 № 215 «Про затвердження нормативного документа системи технічного захисту інформації НД ТЗІ 1.6-005-2013»',
        normativeBaseSection5Document8: 'Наказ від 11.11.2014 № 1886/5 «Про затвердження Порядку роботи з електронними документами у діловодстві та їх підготовки до передавання на архівне зберігання»',
        normativeBaseSection5Document9: 'Наказ від 21.12.2015 № 1719 «Про затвердження Норм часу, необхідного для проведення повірки законодавчо регульованих засобів вимірювальної техніки, що перебувають в експлуатації»',
        normativeBaseSection5Document10: 'Наказ від 01.02.2019 № 316/5/57 «Про позначку кваліфікованого сертифіката відкритого ключа»',
        normativeBaseSection5Document11: 'Наказ від 04.11.2019 № 3398/5 «Про затвердження Порядку подання до центрального засвідчувального органу інформації про діяльність надавачів електронних довірчих послуг та засвідчувального центру»',
        normativeBaseSection5Document12: 'Наказ від 17.01.2020 № 173/5 «Про визнання такими, що втратили чинність наказів Міністерства юстиції України»',
        normativeBaseSection5Document13: 'Наказ від 13.04.2020 № 204 «Про затвердження уніфікованої форми акта, складеного за результатами проведення планового (позапланового) заходу державного нагляду (контролю) щодо дотримання кваліфікованим надавачем електронних довірчих послуг вимог законодавства у сфері електронних довірчих послуг»',
        normativeBaseSection5Document14: 'Наказ від 14.05.2020 № 269 «Про встановлення вимог з безпеки та захисту інформації до кваліфікованих надавачів електронних довірчих послуг та їхніх відокремлених пунктів реєстрації»',
        normativeBaseSection5Document15: 'Наказ від 08.07.2020 № 104 «Про затвердження Порядку ведення Довірчого списку»',
        normativeBaseSection5Document16: 'Наказ від 29.07.2020 № 112 «Про затвердження Порядку ведення реєстру чинних, блокованих та скасованих сертифікатів відкритих ключів»',
        normativeBaseSection5Document20: 'Рекомендації Адміністрації Державної служби спеціального зв’язку та захисту інформації України до встановлення рівнів безпеки та механізмів безпеки приміщень кваліфікованого надавача електронних довірчих послуг від 17.09.2020',
        normativeBaseSection5Document17: 'Наказ від 30.09.2020 № 140/614 «Про встановлення вимог до технічних засобів, процесів їх створення, використання та функціонування у складі інформаційно-телекомунікаційних систем під час надання кваліфікованих електронних довірчих послуг»',
        normativeBaseSection5Document18: 'Наказ від 27.10.2020 № 687 «Про затвердження переліку стандартів та технічних специфікацій, дозволених для реалізації в засобах криптографічного захисту інформації»',
        normativeBaseSection5Document19: 'Лист роз\'яснення щодо відмінності між удосконаленим та кваліфікованим електронним підписом № 1/06-3-1587 від 16.02.2021',
        normativeBaseSection6: 'Архів',
        normativeBaseSection6Document1: 'Закон України «Про електронний цифровий підпис» №852-IV від 22.05.2003 р.',
        normativeBaseSection6Document2: 'Постанова від 26.05.2004 № 680 «Порядок засвідчення наявності електронного документа»',
        normativeBaseSection6Document3: 'Постанова від 13.07.2004 № 903 «Про затвердження Порядку акредитації центру сертифікації ключів»',
        normativeBaseSection6Document4: 'Постанова від 28.10.2004 № 1452 «Порядок застосування електронного цифрового підпису державними органами»',
        normativeBaseSection6Document5: 'Роз’яснення до Постанови № 1452',
        normativeBaseSection6Document6: 'Постанова від 28.10.2004 № 1453 «Типовий порядок здійснення електронного документообігу державними органами»',
        normativeBaseSection6Document7: 'Постанова від 28.10.2004 № 1454 «Порядок обов\'язкової передачі документованої інформації»',
        normativeBaseSection6Document8: 'Наказ від 13.01.2005 № 3 «Про затвердження Правил посиленої сертифікації»',
        normativeBaseSection6Document9: 'Наказ від 10.05.2006 № 50 «Про внесення змін до Правил посиленої сертифікації»',
        normativeBaseSection6Document10: 'Наказ від 24.07.2007 № 143 «Про затвердження Положення про порядок здійснення державного контролю за додержанням вимог законодавства у сфері надання послуг електронного цифрового підпису»',
        normativeBaseSection6Document11: 'Наказ від 26.01.2008 № 8/216 «Про затвердження ліцензійних умов провадження господарської діяльності...»',
        normativeBaseSection6Document12: 'Наказ від 13.08.2010 № 8/229 «Про затвердження Технічних специфікацій форматів представлення базових об\'єктів національної системи електронного цифрового підпису»',
        normativeBaseSection6Document13: 'Наказ від 20.08.2012 № 1236/5/453 «Про затвердження вимог до форматів, структури та протоколів, що реалізуються у надійних засобах електронного цифрового підпису»',
        normativeBaseSection6Document14: 'Наказ від 18.12.2012 № 739 «Про затвердження Вимог до форматів криптографічних повідомлень»',
        normativeBaseSection6Document15: 'Наказ від 27.12.2013 № 2782/5/689 «Про затвердження вимог до алгоритмів, форматів та інтерфейсів, що реалізуються у засобах шифрування та надійних засобах електронного цифрового підпису»',
        normativeBaseSection6Document16: 'Постанова від 30.08.2016 № 378 «Про затвердження Положення про Систему BankID Національного банку України»',
        normativeBaseSection6Document17: 'Наказ від 29.10.2018 № 3373/5 «Про затвердження Порядку ведення Довірчого списку»',
        normativeBaseSection6Document18: 'Наказ від 05.11.2018 № 3440/5 «Про затвердження Порядку ведення реєстру чинних, блокованих та скасованих сертифікатів відкритих ключів»',
        normativeBaseSection6Document21: 'Постанова від 07.11.2018 № 992 «Про затвердження вимог у сфері електронних довірчих послуг та Порядку перевірки дотримання вимог законодавства у сфері електронних довірчих послуг»',
        normativeBaseSection6Document19: 'Наказ від 27.11.2018 № 86 «Про встановлення Вимог до засобів електронної ідентифікації, рівнів довіри до засобів електронної ідентифікації для їх використання у сфері електронного урядування»',
        normativeBaseSection6Document20: 'Наказ від 18.11.2019 № 3563/5/610 «Про встановлення вимог до технічних засобів, процесів їх створення, використання та функціонування у складі інформаційно-телекомунікаційних систем під час надання електронних довірчих послуг»',
        documentsTitle: 'Документи',
        documentsImage1: 'Атестат відповідності',
        documentsImage2: 'Експертний висновок',
        documentsImage3: 'Експертний висновок',
        documentsImage4: 'Експертний висновок',
        documentsLink1: 'Відомості про внесення у Довірчий список',
        documentsLink2: 'Регламент АЦСК',
        documentsLink3: 'Зміни №1',
        documentsLink4: 'Зміни №2',
        documentsLink5: 'Зміни №3',
        documentsLink6: 'Договір надання послуг КЕП (для фізичних осіб)',
        documentsLink7: 'Договір надання послуг КЕП (для юридичних осіб та ФОП)',
        documentsLink8: 'Комплект бланків документів для отримання та відкликання сертифікатів',
        onlineServicesTitle: 'Online-сервіси',
        onlineServicesHelp: 'Якщо у Вас виникли складнощі з отриманням сертифіката, або його використанням, Ви можете звернутися за допомогою:',
        onlineServicesTableRowName1: 'Телефони',
        onlineServicesTableRowContent1Part1: '+380 (56) 797-60-54',
        onlineServicesTableRowContent1Part2: '+380 (56) 797-60-55',
        onlineServicesTableRowName2: 'Електронна пошта',
        onlineServicesTableRowContent2: 'acsk@privatbank.ua',
        frequentlyAskedQuestionsTitle: 'Часті питання',
        frequentlyAskedQuestionsQuestion1: 'Які бувають типи електронних підписів?',
        frequentlyAskedQuestionsAnswer1: 'Удосконалений електронний підпис (УЕП) та Кваліфікований електронний підпис (КЕП).<br/><br/>Різниця між ними в тому, що кваліфікований електронний підпис забезпечує вищий рівень захисту, має високий рівень довіри як засіб електронної ідентифікації та прирівнюється до власноручного підпису.<br/><br/>Удосконалений електронний підпис має середній рівень довіри.',
        frequentlyAskedQuestionsQuestion2: 'Які типи сертифікатів надає АЦСК ПриватБанк?',
        frequentlyAskedQuestionsAnswer2: 'Всі сертифікати - кваліфіковані.<br/><br/>Сертифікати до ключів у файловому сховищі ключів для накладання <span style="font-weight: bold">удосконаленого</span> електронного підпису.<br/><br/>Сертифікати до ключів у хмарному сховищі (SmartID) для накладання <span style="font-weight: bold">кваліфікованого</span> електронного підпису.',
        frequentlyAskedQuestionsQuestion3: 'Як отримати сертифікат файлового сховища ключів (удосконалений підпис)?',
        frequentlyAskedQuestionsAnswer3: 'Відео-інструкція за <a href="https://www.youtube.com/watch?v=9AQqwb7_f4s" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">посиланням</a>.',
        frequentlyAskedQuestionsQuestion4: 'Де знайти завантажене сховище ключів?',
        frequentlyAskedQuestionsAnswer4: 'Завантаження здійснюється у папку, яка вказана у налаштуваннях вашого інтернет-браузера.',
        frequentlyAskedQuestionsQuestion5: 'Чи можна завантажити файл з ключем повторно?',
        frequentlyAskedQuestionsAnswer5: 'Ні. Файлове сховище ключів завантажується тільки один раз. При необхідності можна отримати новий сертифікат та завантажити відповідне файлове сховище ключів.',
        frequentlyAskedQuestionsQuestion6: 'Як відкрити файлове сховище ключів формату jks?',
        frequentlyAskedQuestionsAnswer6: 'Відкривати або розпаковувати сховище ключів формату <span style="font-weight: bold">jks</span> не потрібно. Даний файл готовий для підписання електронних документів відразу після завантаження.',
        frequentlyAskedQuestionsQuestion7: 'Чи можна отримати інший формат файлового сховища окрім jks?',
        frequentlyAskedQuestionsAnswer7: 'Ні. Сховище ключів завантажується тільки формату <span style="font-weight: bold">jks</span>. Конвертація не передбачена.',
        frequentlyAskedQuestionsQuestion8: 'Чим відрізняється відмова від публікації сертифікату від відкликання?',
        frequentlyAskedQuestionsAnswer8: 'Відмова від публікації не впливає на термін дії сертифікату. При відкликанні дія сертифікату припиняється.',
        frequentlyAskedQuestionsQuestion9: 'Як отримати сертифікат SmartID (кваліфікований підпис)?',
        frequentlyAskedQuestionsAnswer9: 'Сертифікат SmartID створюється автоматично під час підписання документу. Для цього оберіть сервіс “Підписати документ” і слідуйте підказкам.',
        frequentlyAskedQuestionsQuestion10: 'Як підписати документ?',
        frequentlyAskedQuestionsAnswer10: 'Відео-інструкція за <a href="https://www.youtube.com/watch?v=0viJYKxVBgo" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">посиланням</a>.',
        frequentlyAskedQuestionsQuestion11: 'Як перевірити підпис в документі?',
        frequentlyAskedQuestionsAnswer11: 'Відео-інструкція за <a href="https://www.youtube.com/watch?v=dn8-UumullE" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">посиланням</a>.',
        frequentlyAskedQuestionsQuestion12: 'Як перевірити сертифікат?',
        frequentlyAskedQuestionsAnswer12: 'Відео-інструкція за <a href="https://www.youtube.com/watch?v=hZ8ElhNboAk" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">посиланням</a>.',
        frequentlyAskedQuestionsQuestion13: 'Як відкликати сертифікат?',
        frequentlyAskedQuestionsAnswer13: 'Відео-інструкція за <a href="https://www.youtube.com/watch?v=78cq-c2fbDk" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">посиланням</a>.',
        frequentlyAskedQuestionsQuestion14: 'Як відмовитись від публікації сертифікату?',
        frequentlyAskedQuestionsAnswer14: 'Відео-інструкція за <a href="https://www.youtube.com/watch?v=eAkoNsBi25o" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">посиланням</a>.',
        frequentlyAskedQuestionsQuestion15: 'Де перевірити термін дії свого сертифікату?',
        frequentlyAskedQuestionsAnswer15: '<ol style="padding-inline-start: 20px; margin-block-end: 0"><li>Відкрийте сторінку “<a href="https://acsk.privatbank.ua/certificates/clients" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">Сертифікати</a>” на сайті АЦСК ПриватБанк”.</li><li>Виберіть вкладку “Особисті”.</li><li>Авторизуйтесь за допомогою Приват24.</li><li>Ознайомтесь з даними зі стовпчику “Термін дії” по вашим сертифікатам.</li></ol>',
        contactsTitle: 'Контакти',
        contactsTableRowName1: 'Країна',
        contactsTableRowContent1: 'Україна',
        contactsTableRowName2: 'Область',
        contactsTableRowContent2: 'Дніпропетровська',
        contactsTableRowName3: 'Місто',
        contactsTableRowContent3: 'Дніпро',
        contactsTableRowName4: 'Повне найменування організації',
        contactsTableRowContent4: 'АКЦІОНЕРНЕ ТОВАРИСТВО КОМЕРЦІЙНИЙ БАНК «ПРИВАТБАНК»',
        contactsTableRowName5: 'Скорочене найменування організації',
        contactsTableRowContent5: 'АТ КБ «ПРИВАТБАНК»',
        contactsTableRowName6: 'Місцезнаходження організації',
        contactsTableRowContent6: '49094, м. Дніпро вул. Набережна Перемоги, 50',
        contactsTableRowName7: 'Віддалені пункти реєстрації',
        contactsTableRowContent7: 'Відсутні',
        contactsTableRowName8: 'Телефони',
        contactsTableRowContent8Part1: '+380 (56) 797-60-54',
        contactsTableRowContent8Part2: '+380 (56) 797-60-55',
        contactsTableRowName9: 'Веб сайт ЦСК',
        contactsTableRowContent9: 'http://acsk.privatbank.ua',
        contactsTableRowName10: 'Електронна пошта',
        contactsTableRowContent10: 'acsk@privatbank.ua',
        contactsScheduleInfo1: 'Графік роботи',
        contactsScheduleInfo2: 'Прийом клієнтів і обробка заявок на сертифікацію ключів',
        contactsScheduleTableName1: 'ПН – ПТ',
        contactsScheduleTableContent1: '8:30 – 17:30',
        contactsScheduleTableName2: 'СБ, ВС',
        contactsScheduleTableContent2: '—',
        smartIdIntro: 'Кваліфікований електронний підпис',
        smartIdContentInstructions: 'Підключення до SmartID',
        smartIdContentInstruction1: 'Договір про підключення інформаційно-комунікаційної системи Учасника до Сервісу «SmartID» АТ КБ «ПриватБанк»',
        smartIdContentInstruction2: 'Заява про приєднання до Договору про підключення інформаційно-комунікаційної системи Учасника до Сервісу «SmartID» АТ КБ «ПриватБанк»',
        smartIdContentInstruction3: 'Інструкція для підключення до сервісу',
        smartIdContentLibraries: 'Бібліотеки',
        smartIdContentLibrary1: 'Java-бібліотека підпису користувача ЦСК (з документацією та прикладами)',
        smartIdContentLibrary2: 'Бібліотека підпису користувача ЦСК для ОС Microsoft Windows c C#-інтерфейсом (з документацією та прикладами)',
        smartIdContentLibrary3: 'JS-бібліотека підпису користувача ЦСК (з документацією та прикладами)',
        smartIdContentLibrary4: 'Бібліотека підпису користувача ЦСК для середовища виконання (фреймворка) .NET Core c C#-інтерфейсом (з документацією та прикладами)',
        smartIdContentLibrary5: 'PHP-бібліотека підпису користувача ЦСК (з документацією та прикладами)',
        smartIdContentLibrary6: 'Бібліотека підпису користувача ЦСК з Python-інтерфейсом для ОС Linux (з документацією та прикладами)',
        smartIdContentLibrary7: 'Бібліотека підпису користувача ЦСК з Python-інтерфейсом для ОС Microsoft Windows (з документацією та прикладами)',
        smartIdContactInformation: 'Зв\'яжіться з нами:',
        smartIdContactInformationMail: 'smartid@privatbank.ua',
        footerAcskInfo: 'Кваліфікований надавач електронних довірчих послуг АЦСК АТ КБ «ПРИВАТБАНК»',
        footerAcskAddress: 'вул. Набережна Перемоги, 50, Дніпро, 49094',
        footerPhone1: '+380 (56) 797-60-54',
        footerPhone2: '+380 (56) 797-60-55',
        footerPrivatBank: '© ПРИВАТБАНК, 2021',
        footerPrivatBankPersonalData: 'Про персональні дані',
        footerPrivatBankPersonalDataLink: 'https://privatbank.ua/personal-information',
        errorTitle: 'Упс',
        errorCode: 'Код',
        errorMessage: 'Повідомлення',
        errorRequestId: 'Ідентифікатор запиту',
        errorRecommendation: 'Будь ласка, спробуйте ще раз або зверніться в службу підтримки',
        100: 'Виникла помилка при отриманні токена авторизації',
        200: 'Виникла помилка при отриманні даних авторизованого користувача',
        300: 'Виникла помилка при отриманні статусу авторизації',
        400: 'Виникла помилка при отриманні списку відкликаних сертифікатів',
        500: 'Виникла помилка при отриманні списку сертифікатів АЦСК',
        600: 'Виникла помилка при отриманні списку особистих сертифікатів',
        700: 'Будь ласка, перейдіть в браузер Safari для отримання сертифікатів',
        710: 'Виникла помилка при отриманні сертифікатів',
        720: 'Виникла помилка при створенні архіву сертифікатів',
        800: 'Будь ласка, перейдіть в браузер Safari для отримання інфо (PDF)',
        810: 'Виникла помилка при отриманні інфо (PDF)',
        820: 'Виникла помилка при створенні архіву інфо (PDF)',
        900: 'Виникла помилка при формуванні заявки на відгук сертифікатів',
        910: 'Виникла помилка при формуванні адреси фрейма відкликання сертифікатів',
        1000: 'Виникла помилка при формуванні заявки на скасування публікації сертифікатів',
        1010: 'Виникла помилка при формуванні адреси фрейма скасування публікації сертифікатів',
        1100: 'Виникла помилка при формуванні адреси фрейма генерації сертифікатів',
        1200: 'Виникла помилка при отриманні списку клієнтських сертифікатів',
        1300: 'Будь ласка, перейдіть в браузер Safari для отримання сертифікатів',
        1310: 'Виникла помилка при отриманні сертифікатів',
        1320: 'Виникла помилка при створенні архіву сертифікатів',
        1400: 'Будь ласка, перейдіть в браузер Safari для отримання інфо (PDF)',
        1410: 'Виникла помилка при отриманні інфо (PDF)',
        1420: 'Виникла помилка при створенні архіву інфо (PDF)',
    },
    en: {
        title: 'Accredited Key Certification Center',
        headerTitle: 'AKCC',
        headerMainPageLink: 'Home page',
        headerCertificatesPageLink: 'Certificates',
        headerNormativeBasePageLink: 'Regulatory framework',
        headerDocumentsPageLink: 'Documents',
        headerSmartIDPageLink: 'SmartID',
        headerOnlineServicesPageLink: 'Online-services',
        headerFrequentlyAskedQuestionsLink: 'FAQ',
        headerContactsPageLink: 'Contacts',
        headerLanguageUk: 'UA',
        headerLanguageEn: 'EN',
        techWorks1: 'Due to «PRIVATBANK» AKCC routine maintenance from',
        techWorks2: 'to',
        techWorks3: 'AKCC services may be temporarily unavailable',
        menuCertificatesPageLink: 'Certificates',
        menuNormativeBasePageLink: 'Regulatory framework',
        menuDocumentsPageLink: 'Documents',
        menuSmartIDPageLink: 'SmartID',
        menuOnlineServicesPageLink: 'Online-services',
        menuFrequentlyAskedQuestionsLink: 'FAQ',
        menuContactsPageLink: 'Contacts',
        menuLogin: 'Login',
        menuMyCertificatesPageLink: 'My Certificates',
        menuLogout: 'Logout',
        mainIntroTitle: 'Accredited Key Certification Center',
        mainIntroTarget: 'Targeted for electronic document management systems',
        mainDescription: 'Save your time and costs of preparing reports and conducting commercial transactions with the help of the QES, which is an analogue and has the same legal force as a handwritten signature.',
        mainApplications: 'Applications and opportunities',
        mainApplication1: 'Replacement of internal and external paper workflow with electronic one, which has the same legal force.',
        mainApplication2: 'Protection of information by reliable cryptographic means that meet state standards.',
        mainApplication3: 'Fixing the time of document signing with QES.',
        mainApplication4: 'Providing the ability to view the status of the certificate by viewing the status of the certificate on the <a href="https://acsk.privatbank.ua/certificates/clients" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">Certificates</a> page and by creating a list of revoked certificates.',
        mainApplication5: 'Restrictions on the use of qualified public key certificates generated by the Provider are specified in the <a href="https://acsk.privatbank.ua/arch/docs/Reglament_ACSK_PB.pdf" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">Regulations</a>.',
        mainGeneralInformation: 'General information',
        mainGeneralInformation1: 'Means of a qualified electronic signature or seal are provided in the form of separate hardware or hardware and software qualified electronic signature, which are part of the software and technical complex, as well as individual software applications or software modules (crypto libraries) that function as part of other software applications.',
        mainRates: 'Rates',
        mainRatesColumn1Name: 'Rate plan',
        mainRatesColumn2Name: 'Price with VAT',
        mainRatesColumn1Content1: 'Individual',
        mainRatesColumn1Content2: '0.00 UAH',
        mainRatesColumn2Content1: 'Individual - entrepreneur',
        mainRatesColumn2Content2: '0.00 UAH',
        mainRatesColumn3Content1: 'Legal entity',
        mainRatesColumn3Content2: '0.00 UAH',
        mainOnlineServices: 'Online-services',
        servicesGeneration: 'Get certificate',
        servicesSign: 'Sign document',
        servicesUnpublication: 'Refuse certificate issue',
        servicesRevocation: 'Revoke certificate',
        servicesVerificationSign: 'Verify signature',
        servicesVerificationCertificate: 'Verify certificate',
        servicesInfoTooltip: 'Video instruction',
        certificatesTitle: 'Certificates',
        certificatesClients: 'To clients',
        certificatesAcsk: 'AKCC',
        certificatesRevoked: 'List of revoked certificates',
        certificatesPersonal: 'Personal',
        clientsCertificatesInn: 'TIN',
        clientsCertificatesInnTooltip1: 'If you do not have a TIN, please enter the series and number of your passport.',
        clientsCertificatesInnTooltip2: 'Example: AE121212',
        clientsCertificatesInnInputPlaceholder: '',
        clientsCertificatesEdrpou: 'EDRPOU',
        clientsCertificatesEdrpouTooltip1: 'If you do not have a registration code (EDRPOU), please enter the series and number of your passport.',
        clientsCertificatesEdrpouTooltip2: 'Example: AE121212',
        clientsCertificatesEdrpouInputPlaceholder: '',
        clientsCertificatesSerialNumber: 'Certificate serial number',
        clientsCertificatesSerialNumberInputPlaceholder: '20-42 symbols',
        clientsCertificatesWithRevoked: 'Including revoked',
        clientsCertificatesSearch: 'Search',
        clientsCertificatesBack: 'Back',
        clientsCertificatesFound: 'Found: {size}',
        clientsCertificatesSearchInfoInn: 'TIN: {inn}',
        clientsCertificatesSearchInfoEdrpou: 'EDRPOU: {edrpou}',
        clientsCertificatesSearchInfoSerialNumber: 'Serial number: {serialNumber}',
        clientsCertificatesSearchInfoWithRevoked: 'Including revoked',
        clientsCertificatesMultiCheckBox: 'Select all',
        clientsCertificatesOwner: 'Owner',
        clientsCertificatesSerial: 'РН',
        clientsCertificatesStatus: 'Status',
        clientsCertificatesStatusActive: 'Active',
        clientsCertificatesStatusRevoked: 'Revoked',
        clientsCertificatesStatusBlocked: 'Blocked',
        clientsCertificatesValidity: 'Validity',
        clientsCertificatesType: 'Type',
        clientsCertificatesTypeSign: 'Signature',
        clientsCertificatesTypeStamp: 'Stamp',
        clientsCertificatesKey: 'Key',
        clientsCertificatesSignJKS: 'File signature',
        clientsCertificatesStampJKS: 'File stamp',
        clientsCertificatesSignSmartID: 'Cloud signature',
        clientsCertificatesStampSmartID: 'Cloud stamp',
        clientsCertificatesChecked: 'Selected:',
        clientsCertificatesCheckedMax: '(max. {max})',
        clientsCertificatesDownloadCertificatesAction: 'Download certificates',
        clientsCertificatesDownloadPdfsAction: 'Download info',
        acskCertificatesLoading: 'Loading...',
        acskCertificatesDownloadAll: 'Download all certificates',
        acskCertificateValidity: 'Validity',
        acskCertificateSerial: 'РН',
        revokedCertificatesLoading: 'Loading...',
        revokedCertificateFullName: 'Full ({name})',
        revokedCertificatePartName: 'Part ({name})',
        revokedCertificateStartDate: 'Last update',
        revokedCertificateNextDate: 'Next update',
        revokedCertificateSerial: 'РН',
        personalCertificatesLoading: 'Loading...',
        personalCertificatesEmpty: 'No results found',
        personalCertificatesSearch: 'Search',
        personalCertificatesAllStatusFilter: 'All statuses',
        personalCertificatesActiveStatusFilter: 'Active',
        personalCertificatesRevokedStatusFilter: 'Revoked',
        personalCertificatesBlockedStatusFilter: 'Blocked',
        personalCertificatesAllTypeFilter: 'All signature types',
        personalCertificatesSignTypeFilter: 'Signatures',
        personalCertificatesStampTypeFilter: 'Stamps',
        personalCertificatesAllKeyFilter: 'All types of keystores',
        personalCertificatesJksKeyFilter: 'File keystores',
        personalCertificatesSmartIdKeyFilter: 'SmartID',
        personalCertificatesAllUserTypeFilter: 'All types of users',
        personalCertificatesIndividualUserTypeFilter: 'Individuals',
        personalCertificatesLegalUserTypeFilter: 'Legal entities',
        personalCertificatesEmployeeUserTypeFilter: 'Bank employees',
        personalCertificatesAllOrgNameFilter: 'All organizations',
        personalCertificatesDefaultSort: 'Default',
        personalCertificatesStartDateSort: 'By start date',
        personalCertificatesRevokedDateSort: 'By revoke date',
        personalCertificatesStatusSort: 'By status',
        personalCertificatesTypeSort: 'By signature type',
        personalCertificatesKeySort: 'By type of keystore',
        personalCertificatesOwnerSort: 'By owner',
        personalCertificatesOrgNameSort: 'By organization',
        personalCertificatesUserTypeSort: 'By user type',
        personalCertificatesMultiCheckBox: 'Select all',
        personalCertificatesNotFound: 'No results found',
        personalCertificatesOwner: 'Owner',
        personalCertificatesSerial: 'РН',
        personalCertificatesStatus: 'Status',
        personalCertificatesStatusActive: 'Active',
        personalCertificatesStatusRevoked: 'Revoked',
        personalCertificatesStatusBlocked: 'Blocked',
        personalCertificatesValidity: 'Validity',
        personalCertificatesType: 'Type',
        personalCertificatesTypeSign: 'Signature',
        personalCertificatesTypeStamp: 'Stamp',
        personalCertificatesKey: 'Key',
        personalCertificatesSignJKS: 'File signature',
        personalCertificatesStampJKS: 'File stamp',
        personalCertificatesSignSmartID: 'Cloud signature',
        personalCertificatesInactiveSignSmartID: 'It is impossible to use the key. Received a newer certificate',
        personalCertificatesStampSmartID: 'Cloud stamp',
        personalCertificatesPublication: 'Publication',
        personalCertificatesPublic: 'Public certificate',
        personalCertificatesUnpublic: 'Non-public certificate',
        personalCertificatesChecked: 'Selected:',
        personalCertificatesCheckedMax: '(max. {max})',
        personalCertificatesDownloadCertificatesAction: 'Download certificates',
        personalCertificatesDownloadPdfsAction: 'Download info',
        personalCertificatesRevokeCertificatesAction: 'Revoke',
        personalCertificatesUnpublicateCertificatesAction: 'Unpublish',
        personalCertificatesGenerateCertificatesAction: 'Generate new',
        personalCertificatesAuthorization: 'To work with personal certificates, you need to log in',
        personalCertificatesAuthorizationButton: 'Login',
        normativeBaseTitle: 'Regulatory framework',
        normativeBaseSection1: 'President of Ukraine',
        normativeBaseSection1Document1: 'Указ «Положення про порядок здійснення криптографічного захисту інформації в Україні»',
        normativeBaseSection2: 'Verkhovna Rada of Ukraine',
        normativeBaseSection2Document1: 'Закон України «Про інформацію» N 2657-XII від 02.10.1992',
        normativeBaseSection2Document2: 'Закон України «Про захист інформації в інформаційно-комунікаційних системах» N 80/94-ВР від 05.07.1994',
        normativeBaseSection2Document3: 'Закон України «Про банки і банківську діяльність» № 2121-III від 07.12.2000',
        normativeBaseSection2Document4: 'Закон України «Про електронні документи та електронний документообіг» № 851-IV від 22.05.2003',
        normativeBaseSection2Document5: 'Закон України «Про захист персональних даних» № 2297-VI від 01.06.2010',
        normativeBaseSection2Document6: 'Закон України «Про доступ до публічної інформації» № 2939-VI від 13.01.2011',
        normativeBaseSection2Document7: 'Закон України «Про ліцензування видів господарської діяльності» № 222-VIII від 02.03.2015',
        normativeBaseSection2Document8: 'Закон України «Про електронну ідентифікацію та електронні довірчі послуги» № 2801-IX від 01.12.2022',
        normativeBaseSection3: 'Cabinet of Ministers of Ukraine',
        normativeBaseSection3Document1: 'Постанова від 16.11.2016 № 821 «Деякі питання ліцензування господарської діяльності з надання послуг у галузі криптографічного захисту інформації (крім послуг електронного цифрового підпису) та технічного захисту інформації за переліком, що визначається Кабінетом Міністрів України»',
        normativeBaseSection3Document2: 'Постанова від 10.05.2018 № 356 «Про внесення змін та визнання такими, що втратили чинність, деяких актів Кабінету Міністрів України у зв’язку з прийняттям Закону України “Про електронні довірчі послуги»',
        normativeBaseSection3Document3: 'Постанова від 19.09.2018 № 749 «Про затвердження Порядку використання електронних довірчих послуг в органах державної влади, органах місцевого самоврядування, підприємствах, установах та організаціях державної форми власності»',
        normativeBaseSection3Document4: 'Постанова від 26.09.2018 № 775 «Про затвердження обов’язкових вимог до Довірчого списку»',
        normativeBaseSection3Document5: 'Постанова від 10.10.2018 № 821 «Про затвердження Порядку зберігання документованої інформації та її передавання центральному засвідчувальному органу в разі припинення діяльності кваліфікованого надавача електронних довірчих послуг»',
        normativeBaseSection3Document6: 'Постанова від 31.10.2018 № 914 «Про затвердження критеріїв, за якими оцінюється ступінь ризику від провадження господарської діяльності у сфері надання електронних довірчих послуг та визначається періодичність проведення планових заходів державного нагляду (контролю) Адміністрацією Державної служби спеціального зв’язку та захисту інформації»',
        normativeBaseSection3Document7: 'Постанова від 28 червня 2024 р. № 764 «Деякі питання електронної ідентифікації та електронних довірчих послуг»',
        normativeBaseSection3Document8: 'Постанова від 18.12.2018 № 1215 «Про затвердження Порядку проведення процедури оцінки відповідності у сфері електронних довірчих послуг»',
        normativeBaseSection3Document9: 'Постанова від 23.01.2019 № 60 «Про затвердження Порядку взаємного визнання українських та іноземних сертифікатів відкритих ключів, електронних підписів, а також використання інформаційно- телекомунікаційної системи центрального засвідчувального органу для забезпечення визнання в Україні електронних довірчих послуг, іноземних сертифікатів відкритих ключів, що використовуються під час надання юридично значущих електронних послуг у процесі взаємодії між суб’єктами різних держав»',
        normativeBaseSection3Document10: 'Постанова від 19.06.2019 № 546 «Про затвердження Положення про інтегровану систему електронної ідентифікації»',
        normativeBaseSection3Document11: 'Постанова від 18.09.2019 № 856 «Питання Міністерства цифрової трансформації»',
        normativeBaseSection3Document12: 'Постанова від 11.12.2019 № 1068 «Про внесення змін до деяких постанов Кабінету Міністрів України»',
        normativeBaseSection3Document13: 'Постанова від 03.03.2020 № 193 «Про реалізацію експериментального проекту щодо забезпечення можливості використання удосконалених електронних підписів і печаток, які базуються на кваліфікованих сертифікатах відкритих ключів»',
        normativeBaseSection3Document14: 'Постанова від 29.04.2020 № 345 «Про реалізацію експериментального проекту щодо забезпечення безперервного надання кваліфікованих електронних довірчих послуг у разі заміни надавача таких послуг»',
        normativeBaseSection3Document15: 'Постанова від 02.09.2020 № 785 «Про реалізацію експериментального проекту щодо використання віддаленого кваліфікованого електронного підпису Смарт-Дія»',
        normativeBaseSection3Document16: 'Постанова від 23.02.2022 № 229 «Про внесення змін до постанов Кабінету Міністрів України від 9 грудня 2020 р. № 1236 і від 29 червня 2021 р. № 677»',
        normativeBaseSection3Document17: 'Постанова від 17.03.2022 № 300 «Деякі питання забезпечення безперебійного функціонування системи надання електронних довірчих послуг»',
        normativeBaseSection3Document18: 'Постанова від 24.05.2022 № 617 «Про внесення змін до постанови Кабінету Міністрів України від 17 березня 2022 р. № 300»',
        normativeBaseSection4: 'National Bank of Ukraine',
        normativeBaseSection4Document1: 'Постанова від 04.07.2007 № 243 «Про затвердження Правил з технічного захисту інформації для приміщень банків, у яких обробляються електронні банківські документи»',
        normativeBaseSection4Document2: 'Постанова від 26.11.2015 № 829 «Про затвердження нормативно-правових актів з питань інформаційної безпеки»',
        normativeBaseSection4Document3: 'Постанова від 14.08.2017 № 78 «Про затвердження Положення про застосування електронного підпису в банківській системі України»',
        normativeBaseSection4Document4: 'Постанова від 28.09.2017 № 95 «Про затвердження Положення про організацію заходів із забезпечення інформаційної безпеки в банківській системі України»',
        normativeBaseSection4Document5: 'Постанова від 19.09.2019 № 116 «Про затвердження Положення про кваліфікованих надавачів електронних довірчих послуг, внесених до Довірчого списку за поданням засвідчувального центру»',
        normativeBaseSection4Document6: 'Постанова від 13.12.2019 № 151 «Про затвердження Положення про застосування цифрового власноручного підпису в банківській системі України»',
        normativeBaseSection4Document7: 'Постанова від 24.12.2019 № 153 «Про затвердження Положення про синхронізацію часу у програмно-технічних комплексах кваліфікованих надавачів електронних довірчих послуг, внесених до Довірчого списку за поданням засвідчувального центру»',
        normativeBaseSection4Document8: 'Постанова від 17.02.2020 № 19 «Про затвердження Правил передавання документованої інформації до засвідчувального центру кваліфікованими надавачами електронних довірчих послуг, відомості про яких унесені до Довірчого списку за поданням засвідчувального центру»',
        normativeBaseSection4Document9: 'Постанова від 17.03.2020 № 32 «Про затвердження Положення про Систему BankID Національного банку України»',
        normativeBaseSection4Document10: 'Постанова від 24.03.2023 № 31 «Про внесення зміни до Положення про порядок ідентифікації та визнання банківських груп»',
        normativeBaseSection4Document11: 'Постанова від 27.03.2023 № 35 «Про затвердження Змін до Положення про кваліфікованих надавачів електронних довірчих послуг, внесених до Довірчого списку за поданням засвідчувального центру»',
        normativeBaseSection5: 'SBU, State Security Service, Ministry of Justice and Ministry of Digital',
        normativeBaseSection5Document1: 'Наказ від 16.05.2007 № 93 «Про затвердження Положення про державну експертизу в сфері технічного захисту інформації»',
        normativeBaseSection5Document2: 'Наказ від 12.06.2007 № 114 «Про затвердження Інструкції про порядок постачання і використання ключів до засобів криптографічного захисту інформації»',
        normativeBaseSection5Document3: 'Наказ від 20.07.2007 № 141 «Про затвердження Положення про порядок розроблення, виробництва та експлуатації засобів криптографічного захисту інформації»',
        normativeBaseSection5Document4: 'Наказ від 23.06.2008 № 100 «Про затвердження Положення про державну експертизу у сфері криптографічного захисту інформації»',
        normativeBaseSection5Document5: 'Наказ від 05.03.2012 № 368/5 «Про затвердження Вимог до написання найменування юридичної особи, її відокремленого підрозділу, громадського формування, що не має статусу юридичної особи, крім організації профспілки»',
        normativeBaseSection5Document6: 'Роз\'яснення Міністерства юстиції України щодо порядку обчислення геш-значення, викладені у Листі Міністерства юстиції України від 15.10.2012 № 12776-026-12/133',
        normativeBaseSection5Document7: 'Наказ від 15.04.2013 № 215 «Про затвердження нормативного документа системи технічного захисту інформації НД ТЗІ 1.6-005-2013»',
        normativeBaseSection5Document8: 'Наказ від 11.11.2014 № 1886/5 «Про затвердження Порядку роботи з електронними документами у діловодстві та їх підготовки до передавання на архівне зберігання»',
        normativeBaseSection5Document9: 'Наказ від 21.12.2015 № 1719 «Про затвердження Норм часу, необхідного для проведення повірки законодавчо регульованих засобів вимірювальної техніки, що перебувають в експлуатації»',
        normativeBaseSection5Document10: 'Наказ від 01.02.2019 № 316/5/57 «Про позначку кваліфікованого сертифіката відкритого ключа»',
        normativeBaseSection5Document11: 'Наказ від 04.11.2019 № 3398/5 «Про затвердження Порядку подання до центрального засвідчувального органу інформації про діяльність надавачів електронних довірчих послуг та засвідчувального центру»',
        normativeBaseSection5Document12: 'Наказ від 17.01.2020 № 173/5 «Про визнання такими, що втратили чинність наказів Міністерства юстиції України»',
        normativeBaseSection5Document13: 'Наказ від 13.04.2020 № 204 «Про затвердження уніфікованої форми акта, складеного за результатами проведення планового (позапланового) заходу державного нагляду (контролю) щодо дотримання кваліфікованим надавачем електронних довірчих послуг вимог законодавства у сфері електронних довірчих послуг»',
        normativeBaseSection5Document14: 'Наказ від 14.05.2020 № 269 «Про встановлення вимог з безпеки та захисту інформації до кваліфікованих надавачів електронних довірчих послуг та їхніх відокремлених пунктів реєстрації»',
        normativeBaseSection5Document15: 'Наказ від 08.07.2020 № 104 «Про затвердження Порядку ведення Довірчого списку»',
        normativeBaseSection5Document16: 'Наказ від 29.07.2020 № 112 «Про затвердження Порядку ведення реєстру чинних, блокованих та скасованих сертифікатів відкритих ключів»',
        normativeBaseSection5Document20: 'Рекомендації Адміністрації Державної служби спеціального зв’язку та захисту інформації України до встановлення рівнів безпеки та механізмів безпеки приміщень кваліфікованого надавача електронних довірчих послуг від 17.09.2020',
        normativeBaseSection5Document17: 'Наказ від 30.09.2020 № 140/614 «Про встановлення вимог до технічних засобів, процесів їх створення, використання та функціонування у складі інформаційно-телекомунікаційних систем під час надання кваліфікованих електронних довірчих послуг»',
        normativeBaseSection5Document18: 'Наказ від 27.10.2020 № 687 «Про затвердження переліку стандартів та технічних специфікацій, дозволених для реалізації в засобах криптографічного захисту інформації»',
        normativeBaseSection5Document19: 'Лист роз\'яснення щодо відмінності між удосконаленим та кваліфікованим електронним підписом № 1/06-3-1587 від 16.02.2021',
        normativeBaseSection6: 'Archive',
        normativeBaseSection6Document1: 'Закон України «Про електронний цифровий підпис» №852-IV від 22.05.2003 р.',
        normativeBaseSection6Document2: 'Постанова від 26.05.2004 № 680 «Порядок засвідчення наявності електронного документа»',
        normativeBaseSection6Document3: 'Постанова від 13.07.2004 № 903 «Про затвердження Порядку акредитації центру сертифікації ключів»',
        normativeBaseSection6Document4: 'Постанова від 28.10.2004 № 1452 «Порядок застосування електронного цифрового підпису державними органами»',
        normativeBaseSection6Document5: 'Роз’яснення до Постанови № 1452',
        normativeBaseSection6Document6: 'Постанова від 28.10.2004 № 1453 «Типовий порядок здійснення електронного документообігу державними органами»',
        normativeBaseSection6Document7: 'Постанова від 28.10.2004 № 1454 «Порядок обов\'язкової передачі документованої інформації»',
        normativeBaseSection6Document8: 'Наказ від 13.01.2005 № 3 «Про затвердження Правил посиленої сертифікації»',
        normativeBaseSection6Document9: 'Наказ від 10.05.2006 № 50 «Про внесення змін до Правил посиленої сертифікації»',
        normativeBaseSection6Document10: 'Наказ від 24.07.2007 № 143 «Про затвердження Положення про порядок здійснення державного контролю за додержанням вимог законодавства у сфері надання послуг електронного цифрового підпису»',
        normativeBaseSection6Document11: 'Наказ від 26.01.2008 № 8/216 «Про затвердження ліцензійних умов провадження господарської діяльності...»',
        normativeBaseSection6Document12: 'Наказ від 13.08.2010 № 8/229 «Про затвердження Технічних специфікацій форматів представлення базових об\'єктів національної системи електронного цифрового підпису»',
        normativeBaseSection6Document13: 'Наказ від 20.08.2012 № 1236/5/453 «Про затвердження вимог до форматів, структури та протоколів, що реалізуються у надійних засобах електронного цифрового підпису»',
        normativeBaseSection6Document14: 'Наказ від 18.12.2012 № 739 «Про затвердження Вимог до форматів криптографічних повідомлень»',
        normativeBaseSection6Document15: 'Наказ від 27.12.2013 № 2782/5/689 «Про затвердження вимог до алгоритмів, форматів та інтерфейсів, що реалізуються у засобах шифрування та надійних засобах електронного цифрового підпису»',
        normativeBaseSection6Document16: 'Постанова від 30.08.2016 № 378 «Про затвердження Положення про Систему BankID Національного банку України»',
        normativeBaseSection6Document17: 'Наказ від 29.10.2018 № 3373/5 «Про затвердження Порядку ведення Довірчого списку»',
        normativeBaseSection6Document18: 'Наказ від 05.11.2018 № 3440/5 «Про затвердження Порядку ведення реєстру чинних, блокованих та скасованих сертифікатів відкритих ключів»',
        normativeBaseSection6Document21: 'Постанова від 07.11.2018 № 992 «Про затвердження вимог у сфері електронних довірчих послуг та Порядку перевірки дотримання вимог законодавства у сфері електронних довірчих послуг»',
        normativeBaseSection6Document19: 'Наказ від 27.11.2018 № 86 «Про встановлення Вимог до засобів електронної ідентифікації, рівнів довіри до засобів електронної ідентифікації для їх використання у сфері електронного урядування»',
        normativeBaseSection6Document20: 'Наказ від 18.11.2019 № 3563/5/610 «Про встановлення вимог до технічних засобів, процесів їх створення, використання та функціонування у складі інформаційно-телекомунікаційних систем під час надання електронних довірчих послуг»',
        documentsTitle: 'Documents',
        documentsImage1: 'Accreditation certificate',
        documentsImage2: 'Conformity certificate',
        documentsImage3: 'Expert conclusion',
        documentsImage4: 'Expert conclusion',
        documentsLink1: 'Information about entries into the Trust List',
        documentsLink2: 'Regulations of AKCC',
        documentsLink3: 'Modification №1',
        documentsLink4: 'Modification №2',
        documentsLink5: 'Modification №3',
        documentsLink6: 'QES service agreement (for individuals)',
        documentsLink7: 'QES service agreement (for legal entities and individual entrepreneurs)',
        documentsLink8: 'Set of document forms to obtain and revoke certificates',
        onlineServicesTitle: 'Online services',
        onlineServicesHelp: 'If you have any difficulties obtaining a certificate, or using it, you can contact for help:',
        onlineServicesTableRowName1: 'Telephones',
        onlineServicesTableRowContent1Part1: '+380 (56) 797-60-54',
        onlineServicesTableRowContent1Part2: '+380 (56) 797-60-55',
        onlineServicesTableRowName2: 'Email',
        onlineServicesTableRowContent2: 'acsk@privatbank.ua',
        frequentlyAskedQuestionsTitle: 'FAQ',
        frequentlyAskedQuestionsQuestion1: 'What are the types of electronic signatures?',
        frequentlyAskedQuestionsAnswer1: 'Advanced Electronic Signature (AES) and Qualified Electronic Signature (QES).<br/><br/>They differ in that a qualified electronic signature gives better protection, has a high level of confidence as electronic identification means and is equated to a handwritten signature.<br/><br/>An Advanced Electronic Signature has a medium level of confidence.',
        frequentlyAskedQuestionsQuestion2: 'What types of certificates are provided by the PrivatBank Accredited Key Certification Center?',
        frequentlyAskedQuestionsAnswer2: 'All certificates are qualified.<br/><br/>Key certificates in a file keystore for applying an <span style="font-weight: bold">advanced</span> electronic signature.<br/><br/>Key certificates in cloud storage (Smart ID) for applying a <span style="font-weight: bold">qualified</span> electronic signature.',
        frequentlyAskedQuestionsQuestion3: 'How can I get a file keystore certificate (advanced signature)?',
        frequentlyAskedQuestionsAnswer3: 'Click <a href="https://www.youtube.com/watch?v=9AQqwb7_f4s" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">here</a> to watch video instructions.',
        frequentlyAskedQuestionsQuestion4: 'Where can I find the downloaded keystore?',
        frequentlyAskedQuestionsAnswer4: 'The download is made to the folder specified in the settings of your Internet browser.',
        frequentlyAskedQuestionsQuestion5: 'Can I re-download the key file?',
        frequentlyAskedQuestionsAnswer5: 'No. The file keystore can be downloaded only once. If necessary, you can get a new certificate and download the corresponding keystore file.',
        frequentlyAskedQuestionsQuestion6: 'How to open a jks file keystore?',
        frequentlyAskedQuestionsAnswer6: 'You do not need to open or extract the <span style="font-weight: bold">jks</span> keystore. This file is ready for signing electronic documents immediately after downloading.',
        frequentlyAskedQuestionsQuestion7: 'Can I get file storage format other than jks?',
        frequentlyAskedQuestionsAnswer7: 'No. The keystore is downloaded only in <span style="font-weight: bold">jks</span> format. No conversion is provided.',
        frequentlyAskedQuestionsQuestion8: 'What is the difference between rejecting to publish a certificate and revoking it?',
        frequentlyAskedQuestionsAnswer8: 'Rejection to publish the certificate does not affect its validity. Revoking the certificate terminates its validity.',
        frequentlyAskedQuestionsQuestion9: 'How can I get a SmartID certificate (qualified signature)?',
        frequentlyAskedQuestionsAnswer9: 'The SmartID certificate is generated automatically when you sign the document. To do this, select the Sign Document service and follow the prompts.',
        frequentlyAskedQuestionsQuestion10: 'How to sign a document?',
        frequentlyAskedQuestionsAnswer10: 'Click <a href="https://www.youtube.com/watch?v=0viJYKxVBgo" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">here</a> to watch video instructions.',
        frequentlyAskedQuestionsQuestion11: 'How to check a signature in a document?',
        frequentlyAskedQuestionsAnswer11: 'Click <a href="https://www.youtube.com/watch?v=dn8-UumullE" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">here</a> to watch video instructions.',
        frequentlyAskedQuestionsQuestion12: 'How to check a certificate?',
        frequentlyAskedQuestionsAnswer12: 'Click <a href="https://www.youtube.com/watch?v=hZ8ElhNboAk" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">here</a> to watch video instructions.',
        frequentlyAskedQuestionsQuestion13: 'How to revoke a certificate?',
        frequentlyAskedQuestionsAnswer13: 'Click <a href="https://www.youtube.com/watch?v=78cq-c2fbDk" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">here</a> to watch video instructions.',
        frequentlyAskedQuestionsQuestion14: 'How to reject publishing a certificate?',
        frequentlyAskedQuestionsAnswer14: 'Click <a href="https://www.youtube.com/watch?v=eAkoNsBi25o" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">here</a> to watch video instructions.',
        frequentlyAskedQuestionsQuestion15: 'Where can I check the validity of my certificate?',
        frequentlyAskedQuestionsAnswer15: '<ol style="padding-inline-start: 20px; margin-block-end: 0"><li>Open the "<a href="https://acsk.privatbank.ua/certificates/clients" target="_blank" rel="noopener noreferrer" style="text-decoration: inherit; color: var(--blue)">Certificates</a>" page on the PrivatBank Accredited Key Certification Center website.</li><li>Go to the "Personal" tab.</li><li>Log in using Privat24.</li><li>Review the "Validity" column for your certificates.</li></ol>',
        contactsTitle: 'Contacts',
        contactsTableRowName1: 'Country',
        contactsTableRowContent1: 'Ukraine',
        contactsTableRowName2: 'Region',
        contactsTableRowContent2: 'Dnipropetrovsk',
        contactsTableRowName3: 'City',
        contactsTableRowContent3: 'Dnipro',
        contactsTableRowName4: 'Full company name',
        contactsTableRowContent4: 'JOINT STOCK COMMERCIAL BANK «PRIVATBANK»',
        contactsTableRowName5: 'Abbreviated company name',
        contactsTableRowContent5: 'JSC CB «PRIVATBANK»',
        contactsTableRowName6: 'Company location',
        contactsTableRowContent6: '50 Naberezhna Peremohy Str., Dnipro, 49094',
        contactsTableRowName7: 'Remote check-in points',
        contactsTableRowContent7: 'None',
        contactsTableRowName8: 'Telephones',
        contactsTableRowContent8Part1: '+380 (56) 797-60-54',
        contactsTableRowContent8Part2: '+380 (56) 797-60-55',
        contactsTableRowName9: 'KCC website',
        contactsTableRowContent9: 'http://acsk.privatbank.ua',
        contactsTableRowName10: 'Email',
        contactsTableRowContent10: 'acsk@privatbank.ua',
        contactsScheduleInfo1: 'Schedule',
        contactsScheduleInfo2: 'Receiving clients and processing applications for key certification',
        contactsScheduleTableName1: 'MON – FRI',
        contactsScheduleTableContent1: '8:30 a.m. – 5:30 p.m',
        contactsScheduleTableName2: 'SAT, SUN',
        contactsScheduleTableContent2: '—',
        smartIdIntro: 'Qualified electronic signature',
        smartIdContentInstructions: 'Join the SmartID',
        smartIdContentInstruction1: 'Agreement on connection the Participant\'s information and communication system to the "SmartID" Service JSC CB "PrivatBank"',
        smartIdContentInstruction2: 'Application for joining the Agreement on connection of the Participant\'s information and communication system to the "SmartID" Service of JSC CB "PrivatBank"',
        smartIdContentInstruction3: 'Service connection instructions',
        smartIdContentLibraries: 'Libraries',
        smartIdContentLibrary1: 'KCC user signature Java library (with documentation and examples)',
        smartIdContentLibrary2: 'KCC user signature library for Microsoft Windows OS with C# interface (with documentation and examples)',
        smartIdContentLibrary3: 'KCC user signature JS library (with documentation and examples)',
        smartIdContentLibrary4: 'KCC user signature library for the execution environment (framework). NET Core with C# interface (with documentation and examples)',
        smartIdContentLibrary5: 'KCC user signature PHP library (with documentation and examples)',
        smartIdContentLibrary6: 'KCC user signature library with Python interface for Linux OS (with documentation and examples)',
        smartIdContentLibrary7: 'KCC user signature library with Python interface for Microsoft Windows OS (with documentation and examples)',
        smartIdContactInformation: 'Contact us:',
        smartIdContactInformationMail: 'smartid@privatbank.ua',
        footerAcskInfo: 'Qualified provider of AKCC electronic trust services of JSC CB «PRIVATBANK»',
        footerAcskAddress: '50 Naberezhna Peremohy Str., Dnipro, 49094',
        footerPhone1: '+380 (56) 797-60-54',
        footerPhone2: '+380 (56) 797-60-55',
        footerPrivatBank: '© PRIVATBANK, 2021',
        footerPrivatBankPersonalData: 'Privacy policy',
        footerPrivatBankPersonalDataLink: 'https://en.privatbank.ua/personal-information',
        errorTitle: 'Oops, something went wrong',
        errorCode: 'Code',
        errorMessage: 'Message',
        errorRequestId: 'Request ID',
        errorRecommendation: 'Please try again or contact our support service',
        100: 'An error occurred while retrieving the authorization token',
        200: 'An error occurred while retrieving the authorized user data',
        300: 'An error occurred while retrieving the authorization status',
        400: 'An error occurred while retrieving the certificate revocation list',
        500: 'An error occurred while retrieving the AKCC certificate list',
        600: 'An error occurred while retrieving the personal certificates list',
        700: 'Please go to Safari browser to get certificates',
        710: 'An error occurred while retrieving certificates',
        720: 'An error occurred while creating the certificate archive',
        800: 'Please go to Safari browser for info (PDF)',
        810: 'An error occurred while retrieving info (PDF)',
        820: 'An error occurred while creating the info archive (PDF)',
        900: 'An error occurred while generating a certificate revocation request',
        910: 'An error occurred while generating the certificate revocation frame address',
        1000: 'An error occurred while generating a request to unpublish certificates',
        1010: 'An error occurred while generating the address of the certificate unpublish frame',
        1100: 'An error occurred while generating the address of the certificate generation frame',
        1200: 'An error occurred while retrieving the client certificates list',
        1300: 'Please go to Safari browser to get certificates',
        1310: 'An error occurred while retrieving certificates',
        1320: 'An error occurred while creating the certificate archive',
        1400: 'Please go to Safari browser for info (PDF)',
        1410: 'An error occurred while retrieving info (PDF)',
        1420: 'An error occurred while creating the info archive (PDF)',
    }
});

export default localizations;
